import React, { useEffect, useState } from 'react'
import "./Footer.css"

import call from "../Images/phone.png"
import whatup from "../Images/whatsup.png"
import Backend_url from '../../Important/BackEnd'
import axios from 'axios'
import Token from '../../Important/TOKEN'

function Footer() {
  const [contactnumber, setcontactsnumbers] = useState([])
  // --------------   fetch marquecontents --------------------------------
  const fetchjson = async () => {
    try {
      axios.get(`${Backend_url}/jsonroute/get`,
        {
          headers: {
            'Authorization': 'Bearer ' + Token,
          },
        }
      ).then((res) => {
        const alljson = res.data.json
        alljson.map((jsondata) => {
          const parsedObject = JSON.parse(jsondata.JsonObject)
          if (parsedObject.name == "uppernavnumber") {
            setcontactsnumbers(parsedObject?.data)
          }
        })
        console.log(res.data)
      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchjson()
  }, []);

  // const data = [
  //   "+91 8103232328",
  //   "+91 9111104359",
  //   "+91 9977604359",
  //   "+91 7970004359",
  //   "+91 9179995552"
  // ]
  return (
    <div>
      <div className="footer sm:!p-0">
        <ul className={`sm:!hidden`}>
          <li>Call us on</li>
          {
            contactnumber.map((item, index) => {
              return <li> <span><img src={call} alt="" /></span>{item}</li>
            })
          }
          {/* <li> <span><img src={call} alt="" /></span> +91 9111104359</li>
          <li> <span><img src={call} alt="" /></span>+91 9977604359</li>
          <li> <span><img src={call} alt="" /></span>+91 7970004359</li>
          <li> <span><img src={whatup} alt="" /></span> +91 9179995552</li> */}
        </ul>
        <ul className={`!hidden sm:!flex items-start px-[5px] py-[3px]`}>
          <li className={`w-auto flex !p-0 items-center justify-center`}>
            Call us on
            <li className={` sm:!p-0 gap-[5px] justify-center items-center`}> <span><img className={` sm:m-0`} src={call} alt="" /></span>+91 8103232328</li>
          </li>

        </ul>
      </div>
    </div>
  )
}

export default Footer
