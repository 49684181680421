import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./Navbar.css";
import logo from "../Images/nav-ogo.png";
import logo1 from "../Images/new.gif";
import { Icon } from '@iconify/react/dist/iconify.js';
import axios from 'axios';
import Backend_url from '../../Important/BackEnd';
import Token from '../../Important/TOKEN';

function Navbar() {
  const location = useLocation(); // Get current location
  const [menu, setMenu] = useState("menu");
  const [togle, settogle] = useState(false);
  const [Mainmenu, setMainmenu] = useState([])
  const [logodata, setlogodata] = useState([])
  // Update the active menu based on the current pathname
  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setMenu("home");
        break;
      case '/About-us':
        setMenu("About");
        break;
      case '/Current-Opening':
        setMenu("current-opening");
        break;
      case '/Payment':
        setMenu("payment");
        break;
      case '/Career/:jobId':
        setMenu("uploadcv");
        break;
      case '/Employe':
        setMenu("employer");
        break;
      case '/contact':
        setMenu("contact-us");
        break;
      default:
        setMenu("menu");
    }
  }, [location]);

  const Menumaster = async () => {
    try {
      await axios.get(`${Backend_url}/MenuMaster/getall`, {
        headers: {
          'Authorization': 'Bearer ' + Token,
        },
      }).then((res) => {
        console.log(res)
        const data = res.data.data
        const Mainmenu = data?.filter((item) => item.GruopName == "null")
        setMainmenu(Mainmenu)
      })

    } catch (error) {
      console.log(error);
    }
  }

  const fetlogo = async () => {
    await axios.get(`${Backend_url}/gallery/images/all`, {
      headers: {
        'Authorization': 'Bearer ' + Token
      }
    }).then((res) => {
      console.log(res.data)
      const gruopimages = res.data.groupedImages
      const logo = gruopimages.logo
      setlogodata(logo)
    })
  }

  useEffect(() => {
    Menumaster()
    fetlogo()
  }, [])

  useEffect(() => {
    console.log(Mainmenu)
  }, [Mainmenu]);

  return (
    <>

      <button onClick={() => settogle(!togle)} className={`sm:block hidden sm:text-[25px] sm:!z-[100] sm:bg-gray-400 sm:p-[5px] sm:fixed sm:top-[10px] sm:right-[10px]`}>
        <Icon icon="fa:bars" style={{ color: "white" }} />
      </button>

      <div className={`navbar justify-between sm:!p-0 sm:right-0 sm:top-0 sm:!h-full sm:!fixed sm:!w-[62%] ${togle ? " sm:!block" : " sm:!hidden"}`}>
        <div className="logo sm:flex sm:items-center sm:justify-start sm:pl-[15px]">
          <img className={`sm:!w-[58%] sm:m-0`} src={logodata[0]?.Imagepath} alt="" />
          <button onClick={() => settogle(!togle)} className={`sm:block hidden sm:text-[25px] sm:!z-[100] sm:bg-gray-400 sm:p-[5px] sm:fixed sm:top-[20px] sm:right-[20px]`}>
            <Icon icon="maki:cross" style={{ color: "white" }} />
          </button>
        </div>

        {/* ----------------btn-----for----------menumobile---------------- */}

        <div className={`menu `}>
          <ul>
            {
              Mainmenu?.map((item) => {
                return <Link to={item.URL}><li className={item.URL == window.location.pathname ? "active" : ""} onClick={() => setMenu(item.Category_sub)}>{item.Category_sub}{item.Category_sub == "CURRENT OPENING" ? < span > <img src={logo1} alt="" /></span> : null}</li></Link>
              })
            }
            {/* <Link to="/About-us"><li className={menu === "About" ? "active" : ""} onClick={() => setMenu("About")}>ABOUT US</li></Link>
            <Link to="/Current-Opening"><li className={menu === "current-opening" ? "active" : ""} onClick={() => setMenu("current-opening")}>CURRENT OPENING <span><img src={logo1} alt="" /></span></li></Link>
            <Link to="/Payment"><li className={menu === "payment" ? "active" : ""} onClick={() => setMenu("payment")}>PAYMENT</li></Link>

            <Link to="/Career/:jobId"><li className={menu === "uploadcv" ? "active" : ""} onClick={() => setMenu("uploadcv")}>UPLOAD CV</li></Link> {/* /career */}
            {/* <Link to="/Employe"><li className={menu === "employer" ? "active" : ""} onClick={() => setMenu("employer")}>EMPLOYER</li></Link>
            <Link to="/contact"><li className={menu === "contact-us" ? "active" : ""} onClick={() => setMenu("contact-us")}>CONTACT US</li></Link> */}
          </ul>
        </div>
      </div >
    </>
  );
}

export default Navbar;
