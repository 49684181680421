import React from 'react'
import "./who.css"
import about from "../Images/about.jpg"

function Who() {
  return (
    <>
       <div className='who-container'>
        <h1>WHO WE ARE</h1>
        <div className='who-container1'>
            <div className="image">
            <img src={about} alt="" />
            </div>
            <div className="para">
            <p className='text-[14px] '> <span className='text-xl text-black-700'>SHREE JOB CONSULTANT </span> is a leading and fast progressing Manpower recruitment firm in Central India providing its services to various sectors all over the India. S.J.C. was founded in 2016 with a distinctive vision in the job placement sector to place our candidates by creating job pool, also satisfaction of client with our services at right time.</p>
        </div>
        </div>
       </div>
    </>
  )
}

export default Who