import React, { useEffect, useState } from 'react'
import Footer from '../../Footer/Footer'
import Footer1 from '../../Footer1/Footer1'
import Navbar from "../../Navbar/Navbar"
import about from "../../Images/about-us.jpg"
import "../CSS/about.css"
import about1 from "../../Images/about-us-sap.jpg"
import Footerlast from '../../Footerlast/Footerlast'
import axios from 'axios'
import Backend_url from '../../../Important/BackEnd'
import Token from '../../../Important/TOKEN'


function About() {
  const [Aboutdata, setaboutdata] = useState([])
  // --------------------fetch hpbodycard --------------------
  const HPbodycard = async () => {
    try {
      await axios.get(`${Backend_url}/HPBodyCard/getall`, {
        headers: {
          'Authorization': `Bearer ${Token}`,
        }
      }).then((res) => {
        const data = res?.data?.data
        const Aboutdata = data.filter((item) => item.Title == "AboutShreeJob")
        console.log(res.data)
        if (Aboutdata) {
          setaboutdata(Aboutdata)
        }
      })

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    HPbodycard()
  }, []);

  useEffect(() => {
    console.log(Aboutdata)
  }, [Aboutdata]);

  return (
    <>
      <Footer />
      <Navbar />
      <div className='about-us sm:!h-auto sm:flex sm:items-center sm:justify-center sm:!flex-row '>
        <div className='about-us1'>
          <h1>ABOUT-US</h1>
        </div>
        <div className='about-us2 '>
          <ul className={`sm:!m-0 sm:!w-auto sm:!right-0`}>
            <li>Home</li>
            <li>/</li>
            <li>About us</li>
          </ul>
        </div>
      </div>
      <div className="about-container">
        <div className="about-job">
          <div className="img">
            <img src={Aboutdata[0]?.CardImage} alt="" className='order-2 md:order-1' />
          </div>
          <div className="throury order-1 md:order-2" >
            <h1>{Aboutdata[0]?.Heading}</h1>
            <p>{Aboutdata[0]?.Details}</p>
            {/* <p>Shree Job Consultant Is professionally managed and established placement agency. The Company is considered amongst one of the pioneer in manpower placement firm in central India.</p>
            <p>We are core Recruitment Company continuously engaged on search and recruitment from bottom to middle level with our primary focus on topnotch professionals at senior management level across industries.</p>
            <p>We attracting candidates and matching them for permanent positions with companies.</p> */}
          </div>
        </div>

        <div className="hr-requiter">
          <div className='hr-para'>
            <p>We work with the companies, building relationships in order to gain a better understanding of their recruitment needs and requirements.</p>
            <p>We sincerely believe to be known as world class HR Recruitment Company, with a global reach can come out of India.</p>
          </div>
          <div className='hr-img'>
            <img src={about1} alt="" />
          </div>
        </div>
      </div>
      <Footer1 />
      <Footerlast />
    </>
  )
}

export default About
