import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import Slid from "../Slider/slider"
import Who from '../Who/Who'
import Mission from '../Mission/Mission'
import Client from '../Client/Client'
import Footer1 from '../Footer1/Footer1'
import Testimonial from '../Testimonial/Testimonial'
import ClientTestimonial from '../ClientTestimonial/ClientTestimonila'
import Footerlast from '../Footerlast/Footerlast'



function Home() {
  return (
    <div>
      <Footer/>
      <Navbar/>
      <Slid/>
      <Who/>
      <Mission/>
      <ClientTestimonial/>
      <Testimonial/>
      <Client/>
      <Footer1/>
     <Footerlast/>
     
    </div>
  )
}

export default Home
