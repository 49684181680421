import React, { useEffect, useState } from 'react';
import './clienttestimonil.css'; // Import the CSS file for styling
import client1 from "../Images/client1.png";
import client2 from "../Images/client2.jpg";
import client3 from "../Images/client3.png";
import client4 from "../Images/client4.webp";
import client5 from "../Images/client5.png";
import Sunitpathak from "../imges/HiraPower.png";
import Navratan from "../imges/PIL.jpg"
import RahulSingh from "../imges/MahendraSponge.png"
import Murti from "../imges/Sardaimg.jpg"
import Mivansteel from "../imges/miwanSteel.webp"
import client6 from "../Images/7.png";
import Backend_url from '../../Important/BackEnd';
import Token from '../../Important/TOKEN';
import axios from 'axios';

const ClientTestimonial = () => {
  const [Testimonials, setTestimonials] = useState([])
  const testimonials = [
    {
      name: "Sumit Pathak",
      position: "Assistant Manager Mechanical Pellet Plant",
      img: Sunitpathak,
      text: "Shree Job Consultancy made the hiring process incredibly smooth. Their team was professional and understood our requirements precisely. We quickly found candidates who perfectly fit our roles.",
    },
    {
      name: "Navratan Patel",
      position: "Manager Safety ",
      img: Navratan,
      text: "We were impressed by the quality of candidates Shree Job Consultancy provided. Each candidate was well-vetted and aligned with our company’s values. This saved us a lot of time and effort.",
    },
    {
      name: "Santanu Acharya",
      position: "Assistant Manager CMS",
      img: Mivansteel,
      text: "What sets Shree Job Consultancy apart is their promptness. We had an urgent need for specialized roles, and they delivered qualified candidates within days. Truly reliable!",
    },
    {
      name: "Rahul Singh",
      position: "DGM Administration",
      img: RahulSingh,
      text: "Throughout the recruitment process, the team at Shree Job Consultancy maintained clear communication. They were always available to answer our queries and provided constant support until we found the right fit.",
    },
    {
      name: "Mr. Murti",
      position: "Group HR",
      img: Murti,
      text: "Shree Job Consultancy’s understanding of our industry was evident. They sourced candidates with specific skill sets that are hard to find, and this has been key to scaling our operations effectively.",
    }
    // {
    //   name: "Chief Operating Officer, Gurushree",
    //   img: <img src={client6} alt='' />,
    //   text: "Shree Job Consultancy has consistently provided top-notch talent for our growing team. Their dedication to understanding our business needs is commendable.",
    // }
  ];

  // State to track the index for the current set of testimonials (4 at a time)
  const [currentIndex, setCurrentIndex] = useState(0);
  const testimonialsPerPage = 4;

  // Function to automatically change to the next set of testimonials
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + testimonialsPerPage) % Testimonials.length);
    }, 3000); // Adjust the time interval for the automatic slide (3000ms = 3 seconds)

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [Testimonials?.length]);

  // Get the current set of testimonials (4 at a time)
  let visibleTestimonials = Testimonials.slice(
    currentIndex,
    currentIndex + testimonialsPerPage
  );

  // If the length of visibleTestimonials is less than 4, fill it with the next testimonials from the start
  if (visibleTestimonials.length < testimonialsPerPage) {
    visibleTestimonials = [
      ...visibleTestimonials,
      ...Testimonials.slice(0, testimonialsPerPage - visibleTestimonials.length)
    ];
  }

  // ----------------------fetch testimonial-----------------------------------------
  const fetchtestimonial = async function () {
    try {
      axios.get(`${Backend_url}/Testimonial/getall`,
        {
          headers: {
            'Authorization': 'Bearer ' + Token,
          },
        }
      ).then((res) => {
        // const alljson = res.data.json
        setTestimonials(res?.data?.data)
        console.log(res.data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchtestimonial()
  }, []);
  return (
    <>
      <div className="client-head">
        <h2> WHAT OUR CLIENTS ARE SAYING </h2>
        <div className="client-testimonial-container">
          <div className="client-testimonial">
            {visibleTestimonials.map((testimonial, index) => (
              <div key={index} className="client-testimonial-card">

                <div className="client-candidate-info">
                  <img src={testimonial.Image} className={`w-[100px] ${testimonial.name == "Sumit Pathak" ? "bg-black px-[4px]" : null} h-[40px]`} />
                  <p className="client-name">{testimonial.Name}</p>
                  <p className={`text-[15px] font-[200]`}>{testimonial.Designation}</p>

                </div>
                <div className="client-testimonial-text">
                  <p className="client-testimonial-text1">"{testimonial.Details}"</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientTestimonial;



