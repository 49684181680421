import React, { useEffect, useState } from 'react'
import Footer from '../../Footer/Footer'
import Footer1 from '../../Footer1/Footer1'
import "../CSS/Employe.css"
import Navbar from '../../Navbar/Navbar'
import Footerlast from '../../Footerlast/Footerlast'
import employee from "../../Images/employee.jpeg"
import PhoneInput from 'react-phone-input-2'; // Import PhoneInput
import 'react-phone-input-2/lib/style.css';  // Import PhoneInput CSS
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios'
import Backend_url from '../../../Important/BackEnd'
import Token from '../../../Important/TOKEN'
import swal from 'sweetalert';

function Employe() {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    officeNumber: '',
    firmName: '',
    email: '',
    address: '',
    position: '',
    jobDescription: '',
    vacancy: '',
    experience: '',
    salary: '',
    location: ''
  });

  const [captcha, setCaptcha] = useState('');
  const [captchaInput, setCaptchaInput] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, mobile: value });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Submit form logic here (e.g., send formData to backend)
  //   console.log(formData);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "Do you want to proceed with this action?",
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: "Yes, proceed"
      },
      dangerMode: true,
    }).then(async (willConfirm) => {
      if (willConfirm) {
        try {
          const data = {
            "ContactNo": formData.mobile,
            "OfficeNo": formData.officeNumber,
            "Name": formData.name,
            "FirmName": formData.firmName,
            "Email": formData.email,
            "Address": formData.address,
            "Position": formData.position,
            "Job_Description": formData.jobDescription,
            "Vacancy": formData.vacancy,
            "Experience": formData.experience,
            "Salary": formData.salary,
            "Location": formData.location
          }

          await axios.post(`${Backend_url}/Employee/create`, data, {
            headers: {
              'Authorization': 'Bearer ' + Token
            }
          }).then((res) => {
            alert(`Employee created successfully`)
            console.log(res)
          })

        } catch (error) {
          console.log(error);
        }

         
        // Place any action to execute upon confirmation here
      } else {
        swal("Cancelled", "Your action was not completed.", "error");
      }
    });
    // const confirm = window.confirm(`Are you sure you want to submit with all this data?`)
    // if (!confirm) return
    // CAPTCHA validation
    if (captcha !== captchaInput) {
      alert('Invalid CAPTCHA, please try again.');
      return;
    }
    console.log(formData);

    // Submit form logic here (e.g., send formData to backend)
  };

  // CAPTCHA Generation Logic
  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const value = Math.random().toString(36).substring(2, 7);
    setCaptcha(value);
  };


  return (
    <>
      <Footer />
      <Navbar />
      <div className='employe-footer sm:!flex-row sm:!p-0 sm:py-[15px]'>
        <div className='employe1'>
          <h1>Employer</h1>
        </div>
        <div className='employe2 '>
          <ul className={`sm:!m-0 sm:!w-auto sm:!right-0`}>
            <li>Home</li>
            <li>/</li>
            <li>Employe</li>
          </ul>
        </div>
      </div>
      <h1 className='main-heading'>Hello Employer, Kindly fill the below form for your vacancy requirement and details.</h1>
      <div className="Employe">

        <div className="employee-img">
          <img src={employee} alt="" />
        </div>
        <div className='form-container'>
          <form className="form-details" onSubmit={handleSubmit}>
            <div className="client-detail">
              <h1>Client Details Form</h1>

              <div className="form-group1 sm:flex-col">
                <label htmlFor="mobile">Contact Number</label>
                <PhoneInput
                  country={'in'} // Set default country
                  value={formData.mobile}
                  onChange={handlePhoneChange} // Handle phone input change
                  className="PhoneInput"
                  inputProps={{
                    name: 'mobile',
                    required: true,
                    autoFocus: false
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="officeNumber">Office Number</label>
                <input
                  type="text"
                  name="officeNumber"
                  placeholder="Office Number"
                  value={formData.officeNumber}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="firmName">Firm Name</label>
                <input
                  type="text"
                  name="firmName"
                  placeholder="Firm Name"
                  value={formData.firmName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Mail ID</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Mail ID"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  name="address"
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="requirement-details">
              <h1>Requirement Details form</h1>
              <div className="form-group">
                <label htmlFor="position">Position</label>
                <input
                  type="text"
                  name="position"
                  placeholder="Position"
                  value={formData.position}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="jobDescription">Job Description</label>
                <input
                  type="text"
                  name="jobDescription"
                  placeholder="Job Description"
                  value={formData.jobDescription}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="vacancy">Vacancy</label>
                <input
                  type="text"
                  name="vacancy"
                  placeholder="Vacancy"
                  value={formData.vacancy}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="experience">Experience</label>
                <input
                  type="text"
                  name="experience"
                  placeholder="Experience"
                  value={formData.experience}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="salary">Salary</label>
                <input
                  type="text"
                  name="salary"
                  placeholder="Salary"
                  value={formData.salary}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="location">Location</label>
                <input
                  type="text"
                  name="location"
                  placeholder="Location"
                  value={formData.location}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            {/* Add CAPTCHA field here */}


            <div className="captcha">

              <label htmlFor="captchaInput">Enter CAPTCHA</label>
              <div className="captch-div">
                <div className="captcha-box">
                  {captcha}
                </div>
                <button type="captcha-refresh" onClick={generateCaptcha} className='captcha-refresh'>
                  <i className='fa fa-refresh'></i>
                </button>
              </div>
              <input className='mt-[-70px]'
                type="text"
                name="captchaInput"
                placeholder="Enter the CAPTCHA"
                value={captchaInput}
                onChange={(e) => setCaptchaInput(e.target.value)}
                required
              />

            </div>



            <div className=' employe-btn'>
              <button>Submit</button>
              <button>Cancel</button>
            </div>
          </form>
          {/* <div className="staff">
            <h1>Are you looking for a staff</h1>
            <form action="" className=' staff-form'>
              <div className="form-group">
                <label htmlFor="location">contact number</label>
                <input
                  type="text"
                  name="conatct"
                  placeholder="contact"
                  value={formData.location}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="location">name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="name"
                  value={formData.location}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  name="address"
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="staff-btn">
                <button>yes i agree</button>
              </div>
            </form>
          </div> */}
        </div>
      </div>
      <Footer1 />
      <Footerlast />
    </>
  );
}
export default Employe;

// import React, { useState } from 'react';
// import PhoneInput from 'react-phone-input-2'; // Import PhoneInput
// import 'react-phone-input-2/lib/style.css';  // Import PhoneInput CSS
// import Footer from '../../Footer/Footer';
// import Footer1 from '../../Footer1/Footer1';
// import "../CSS/Employe.css";
// import Navbar from '../../Navbar/Navbar';
// import Footerlast from '../../Footerlast/Footerlast';
// import employee from "../../Images/employee.jpeg";

// function Employe() {
//   const [formData, setFormData] = useState({
//     name: '',
//     mobile: '',
//     officeNumber: '',
//     firmName: '',
//     email: '',
//     address: '',
//     position: '',
//     jobDescription: '',
//     vacancy: '',
//     experience: '',
//     salary: '',
//     location: ''
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handlePhoneChange = (value) => {
//     setFormData({ ...formData, mobile: value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Submit form logic here (e.g., send formData to backend)
//     console.log(formData);
//   };

//   return (
//     <>
//       <Footer />
//       <Navbar />
//       <div className='employe-footer'>
//         <div className='employe1'>
//           <h1>Employer</h1>
//         </div>
//         <div className='employe2'>
//           <ul>
//             <li>Home</li>
//             <li>/</li>
//             <li>Employe</li>
//           </ul>
//         </div>
//       </div>
//       <h1 className='main-heading'>Hello Employer, Kindly fill the below form for your vacancy requirement and details.</h1>
//       <div className="Employe">
//         <div className="employee-img">
//           <img src={employee} alt="" />
//         </div>
//         <div className='form-container'>
//           <form className="form-details" onSubmit={handleSubmit}>
//             <div className="client-detail">
//               <h1>Client Details Form</h1>

//               <div className="form-group">
//                 <label htmlFor="mobile">Contact Number</label>
//                 <PhoneInput
//                   country={'us'} // Set default country
//                   value={formData.mobile}
//                   onChange={handlePhoneChange} // Handle phone input change
//                   className="PhoneInput"
//                   inputProps={{
//                     name: 'mobile',
//                     required: true,
//                     autoFocus: false
//                   }}
//                 />
//               </div>

//               <div className="form-group">
//                 <label htmlFor="officeNumber">Office Number</label>
//                 <input
//                   type="text"
//                   name="officeNumber"
//                   placeholder="Office Number"
//                   value={formData.officeNumber}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>

//               <div className="form-group">
//                 <label htmlFor="name">Name</label>
//                 <input
//                   type="text"
//                   name="name"
//                   placeholder="Name"
//                   value={formData.name}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>

//               <div className="form-group">
//                 <label htmlFor="firmName">Firm Name</label>
//                 <input
//                   type="text"
//                   name="firmName"
//                   placeholder="Firm Name"
//                   value={formData.firmName}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>

//               <div className="form-group">
//                 <label htmlFor="email">Mail ID</label>
//                 <input
//                   type="email"
//                   name="email"
//                   placeholder="Mail ID"
//                   value={formData.email}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>

//               <div className="form-group">
//                 <label htmlFor="address">Address</label>
//                 <input
//                   type="text"
//                   name="address"
//                   placeholder="Address"
//                   value={formData.address}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>
//             </div>

//             <div className="requirement-details">
//               <h1>Requirement Details Form</h1>
//               <div className="form-group">
//                 <label htmlFor="position">Position</label>
//                 <input
//                   type="text"
//                   name="position"
//                   placeholder="Position"
//                   value={formData.position}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>

//               <div className="form-group">
//                 <label htmlFor="jobDescription">Job Description</label>
//                 <input
//                   type="text"
//                   name="jobDescription"
//                   placeholder="Job Description"
//                   value={formData.jobDescription}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>

//               <div className="form-group">
//                 <label htmlFor="vacancy">Vacancy</label>
//                 <input
//                   type="text"
//                   name="vacancy"
//                   placeholder="Vacancy"
//                   value={formData.vacancy}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>

//               <div className="form-group">
//                 <label htmlFor="experience">Experience</label>
//                 <input
//                   type="text"
//                   name="experience"
//                   placeholder="Experience"
//                   value={formData.experience}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>

//               <div className="form-group">
//                 <label htmlFor="salary">Salary</label>
//                 <input
//                   type="text"
//                   name="salary"
//                   placeholder="Salary"
//                   value={formData.salary}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>

//               <div className="form-group">
//                 <label htmlFor="location">Location</label>
//                 <input
//                   type="text"
//                   name="location"
//                   placeholder="Location"
//                   value={formData.location}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>
//             </div>

//             <div className='employe-btn'>
//               <button type="submit">Submit</button>
//               <button type="button">Cancel</button>
//             </div>
//           </form>
//         </div>
//       </div>
//       <Footer1 />
//       <Footerlast />
//     </>
//   );
// }

// export default Employe;

