import React from 'react'

const SendEnquiry = ({ openModal, setopentModal }) => {

    if (!openModal) {
        return null
    }

    return (
        <>
            <div className={`w-screen top-0 h-screen fixed flex items-center justify-center`}>
                <div className={`w-[50%] sm:w-[65%] h-auto bg-white rounded-md py-[15px] px-[20px] z-[2] absolute`}>
                    <div className={`w-full h-auto px-[0px] py-[10px] flex items-center justify-between`}>
                        <h2 className={` font-[900] font-serif text-[23px] text-[#4f76e3]`}>Send Enquiry</h2>
                        <button onClick={() => setopentModal(!openModal)} className={`w-auto sm:!flex  sm:!items-center sm:!justify-center h-auto text-2xl text-red-400`}>x</button>
                    </div>
                    <div className={`w-full h-auto flex items-start justify-center flex-col`}>
                        <h2 className={` font-[600] py-[10px]`}>To send enquiry please contact us on:</h2>
                        <ul className={`sm:w-full `}>
                            <li className={`flex sm:flex-col sm:items-start items-center py-[4px] gap-[5px] sm:!gap-0 justify-start`}>
                                <span className={`font-[500]`}>
                                    <i class="fa-solid fa-phone" style={{ marginRight: '5px' }}></i>
                                    Mobile No:-</span><p className={` sm:w-full sm:h-auto`}>+91 8103232328, +91 9111104359</p>
                            </li>
                            <li className={`flex sm:flex-col sm:gap-0 sm:items-start items-center gap-[5px] py-[4px] justify-start`}>
                                <span className={`font-[500] gap-1`}>
                                    <i class="fa-solid fa-envelope" style={{ marginRight: '5px' }}></i>
                                    Email:-</span><p className='sm:!pr-[10px]'>shreejobconsultant@gmail.com</p>
                            </li>
                            <li className={`flex sm:gap-0 items-center sm:flex-col sm:items-start gap-[5px] py-[4px] justify-start`}>
                                <span className={`font-[500] gap-1 `}>
                                    <i class="fa-brands fa-linkedin-in" style={{ marginRight: '5px' }} ></i>
                                    Linked In:-</span><p>shreejobconsultant@gmail.com</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div onClick={() => setopentModal(!openModal)} className={` w-[100%] h-[100%] bg-black opacity-40 left-0 absolute z-[1]`} />
            </div>
        </>
    )
}

export default SendEnquiry