import React, { useEffect, useState } from 'react'
import "./mission.css"
import poster from "../Images/poster.jpg"
import Backend_url from '../../Important/BackEnd'
import Token from '../../Important/TOKEN'
import axios from 'axios'


function Mission() {
  const [mission, setmission] = useState(null)
  const [missionpost, setmissionposter] = useState([])
  const fetchjson = async () => {
    try {
      await axios.get(`${Backend_url}/jsonroute/get`,
        {
          headers: {
            'Authorization': 'Bearer ' + Token,
          },
        }
      ).then((res) => {
        const alljson = res.data.json
        alljson.map((jsondata) => {
          const parsedObject = JSON.parse(jsondata.JsonObject)
          if (parsedObject.name == "visionmision") {
            setmission(parsedObject?.data)
          }
        })
        console.log(res.data)
      })
    } catch (error) {
      console.log(error);
    }
  }

  // ----------------------fetchvisionmision --------------------
  const fetchvisionmision = async () => {
    try {
      await axios.get(`${Backend_url}/gallery/images/all`, {
        headers: {
          'Authorization': 'Bearer ' + Token
        }
      }).then((res) => {
        console.log(res.data)
        const data = res.data.groupedImages.Visionmission
        if (data) {
          setmissionposter(data)
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchjson()
    fetchvisionmision()
  }, []);

  useEffect(() => {
    console.log(missionpost)
  }, [missionpost]);
  return (
    <>
      <div className='mission-container'>
        <div className='mission'>
          <h1>{mission?.mainhead}</h1>
          <h4>{mission?.subhead1}</h4>
          <h6>" We help you find better jobs "</h6>
          <p>{mission?.subhead1para}</p>
          <h4>{mission?.subhead2}</h4>
          <p>{mission?.subhead2para}</p>
        </div>
        <div className="poster">
          <img src={missionpost[0]?.Imagepath} alt="" />
        </div>
      </div>
    </>
  )
}

export default Mission