import { Route, Routes } from "react-router-dom";
import Home from "./Components/Home/Home";
import About from "./Components/Pages/Page/About";
import Currenopening from "./Components/Pages/Page/Currenopening";
import Payment from "./Components/Pages/Page/Payment";
import Career from "./Components/Pages/Page/Career"
import Employe from "./Components/Pages/Page/Employe";
import Contact from "./Components/Pages/Page/Contact";

function App() {
  return (
    <div className="App">
      <Routes>
       <Route path="/" element={<Home/>} />
       <Route path="/About-us" element={<About/>} />
       <Route path="/Current-Opening" element={<Currenopening/>} />
       <Route path="/Payment" element={<Payment/>} />
       <Route path="/Career/:jobId" element={<Career/>} />
       <Route path="/Employe" element={<Employe/>} />
       <Route path="/contact" element={<Contact/>} />
      
       </Routes>
    </div>
  );
}

export default App;




