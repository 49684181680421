// import React, { useEffect, useState } from 'react';
// import './testimonial.css'; // Import the CSS file for styling
// import man from "../Images/man.png";
// import man1 from "../Images/man1.png";
// import woman from "../Images/woman.png";
// import woman1 from "../Images/woman1.png";

// // const Testimonial = () => {
// //   // Array of candidate testimonials
// //   const testimonials = [
// //     {
// //       name: "John Doe ",
// //       img: <img src={man} alt='' />,
// //       text: "Shree Job provides good placements in both IT and non-IT companies. They have a strong network and offer many job opportunities across different fields. They handle interviews well and can manage any issues that come up. They are a reliable job provider.",
// //     },
// //     {
// //       name: "Manisha Kashania",
// //       img: <img src={man1} alt='' />,
// //       text: "Shree Job Solution helped me get jobs at two companies, Whirlpool and another one. Thank you so much, Shree Job Solution. Their service is excellent. I’ve been in touch with them for 12 years",
// //     },
// //     {
// //       name: "Mike Johnson ",
// //       img: <img src={woman} alt='' />,
// //       text: "The work-life balance and supportive environment at Shree have allowed me to excel in my career while maintaining a healthy personal life. It's a fantastic place to work.",
// //     },
// //     {
// //       name: "sadhana mishra ",
// //       img: <img src={man} alt='' />,
// //       text: "This consultancy is very helpful and good for your future. They provide great guidance, support, and encouragement, offering the best platform for your career. I’m completely satisfied with their service.",
// //     },
// //     {
// //       name: "Ayush Nigam ",
// //       img: <img src={man1} alt='' />,
// //       text: "Shree Job Consultancy offers not just job advice but also personal guidance. I met Prashant Sir 10 years ago for recruitment, and his guidance was excellent. They provided great service until I got placed. I found jobs in the finance sector through them three times.",
// //     },
// //     {
// //       name: "Pushpalata Mantri ",
// //       img: <img src={woman} alt='' />,
// //       text: "Shree Job Consultancy is not fake at all. Prashant Sir was very helpful and encouraging, and because of him, I got a job in the hospitality sector. It’s a good placement agency, and I recommend it to anyone looking for a job. Keep in mind, Prashant Sir does charge for his services, but the rates are reasonable.",
// //     },
// //   ];

// //   // State to track the index for the current set of testimonials (4 at a time)
// //   const [currentIndex, setCurrentIndex] = useState(0);
// //   const testimonialsPerPage = 4;

// //   // Function to automatically change to the next set of testimonials
// //   useEffect(() => {
// //     const intervalId = setInterval(() => {
// //       setCurrentIndex((prevIndex) => (prevIndex + testimonialsPerPage) % testimonials.length);
// //     }, 8000); // Adjust the time interval for the automatic slide (5000ms = 5 seconds)

// //     // Cleanup the interval on component unmount
// //     return () => clearInterval(intervalId);
// //   }, [testimonials.length]);

// //   // Get the current set of testimonials (4 at a time)
// //   const visibleTestimonials = testimonials.slice(
// //     currentIndex,
// //     currentIndex + testimonialsPerPage
// //   );

// //   return (
// //     <>
// //       <div className="head">
// //         <h2> Candidates Testimonial </h2>
// //         <div className="testimonial-container">
// //           <div className="testimonial">
// //             {visibleTestimonials.map((testimonial, index) => (
// //               <div key={index} className="testimonial-card">
// //                 <div className="candidate-info">
// //                   <p className="name">{testimonial.name}</p>
// //                 </div>
// //                 <div className="testimonial-text">
// //                   {testimonial.img}
// //                   <p className="testimonial-text1">"{testimonial.text}"</p>
// //                 </div>
// //               </div>
// //             ))}
// //           </div>
// //         </div>
// //       </div>
// //     </>
// //   );
// // };

// // export default Testimonial;

// // import React, { useEffect, useState } from 'react';
// // import './clienttestimonil.css'; // Import the CSS file for styling
// // import client1 from "../Images/client1.webp";
// // import client2 from "../Images/client2.jpeg";
// // import client3 from "../Images/client3.jpg";
// // import client4 from "../Images/client4.jpg";
// // import client5 from "../Images/client5.jpeg";
// // import client6 from "../Images/client6.avif";

// const ClientTestimonial = () => {
//   // Array of candidate testimonials
//   const testimonials = [
//     {
//       name: "John Doe ",
//       img: <img src={man} alt='' />,
//       text: "Shree Job provides good placements in both IT and non-IT companies. They have a strong network and offer many job opportunities across different fields. They handle interviews well and can manage any issues that come up. They are a reliable job provider.",
//     },
//     {
//       name: "Manisha Kashania",
//       img: <img src={man1} alt='' />,
//       text: "Shree Job Solution helped me get jobs at two companies, Whirlpool and another one. Thank you so much, Shree Job Solution. Their service is excellent. I’ve been in touch with them for 12 years",
//     },
//     {
//       name: "Mike Johnson ",
//       img: <img src={woman} alt='' />,
//       text: "The work-life balance and supportive environment at Shree have allowed me to excel in my career while maintaining a healthy personal life. It's a fantastic place to work.",
//     },
//     {
//       name: "sadhana mishra ",
//       img: <img src={man} alt='' />,
//       text: "This consultancy is very helpful and good for your future. They provide great guidance, support, and encouragement, offering the best platform for your career. I’m completely satisfied with their service.",
//     },
//     {
//       name: "Ayush Nigam ",
//       img: <img src={man1} alt='' />,
//       text: "Shree Job Consultancy offers not just job advice but also personal guidance. I met Prashant Sir 10 years ago for recruitment, and his guidance was excellent. They provided great service until I got placed. I found jobs in the finance sector through them three times.",
//     },
//     {
//       name: "Pushpalata Mantri ",
//       img: <img src={woman} alt='' />,
//       text: "Shree Job Consultancy is not fake at all. Prashant Sir was very helpful and encouraging, and because of him, I got a job in the hospitality sector. It’s a good placement agency, and I recommend it to anyone looking for a job. Keep in mind, Prashant Sir does charge for his services, but the rates are reasonable.",
//     },
//   ];
//   // State to track the index for the current set of testimonials (4 at a time)
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const testimonialsPerPage = 4;

//   // Function to automatically change to the next set of testimonials
//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + testimonialsPerPage) % testimonials.length);
//     }, 3000); // Adjust the time interval for the automatic slide (3000ms = 3 seconds)

//     // Cleanup the interval on component unmount
//     return () => clearInterval(intervalId);
//   }, [testimonials.length]);

//   // Get the current set of testimonials (4 at a time)
//   let visibleTestimonials = testimonials.slice(
//     currentIndex,
//     currentIndex + testimonialsPerPage
//   );

//   // If the length of visibleTestimonials is less than 4, fill it with the next testimonials from the start
//   if (visibleTestimonials.length < testimonialsPerPage) {
//     visibleTestimonials = [
//       ...visibleTestimonials,
//       ...testimonials.slice(0, testimonialsPerPage - visibleTestimonials.length)
//     ];
//   }

//   return (
//     <>
//       <div className="client-head">
//         <h2> What Our Clients Say </h2>
//         <div className="client-testimonial-container">
//           <div className="client-testimonial">
//             {visibleTestimonials.map((testimonial, index) => (
//               <div key={index} className="client-testimonial-card">
//                 <div className="client-candidate-info">
//                   <p className="client-name">{testimonial.name}</p>
//                 </div>
//                 <div className="client-testimonial-text">
//                   {testimonial.img}
//                   <p className="client-testimonial-text1">"{testimonial.text}"</p>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ClientTestimonial;




import React, { useEffect, useState } from 'react';
import './testimonial.css'; // Import the CSS file for styling
import man from "../Images/man.png";
import man1 from "../Images/man1.png";
import woman from "../Images/woman.png";
import woman1 from "../Images/woman1.png";
import axios from 'axios';
import Token from '../../Important/TOKEN';
import Backend_url from '../../Important/BackEnd';


const ClientTestimonial = () => {
  const [Testimonial, setTestimonials] = useState([])
  const testimonials = [
    {
      name: "Subrat Mishra",
      img: <img src={man} alt='' />,
      text: "Excellent services provided by Shree job consultant ...i have great experience. If you are looking for a dream job then connect with this .Staff behaviour are also very polite and friendly with Reliable culture. Great experience",
    },
    {
      name: "Rahul Upadhyay",
      img: <img src={man1} alt='' />,
      text: "Very good So much helped with my search for job, kept me informed at all times and were in contact regularly to give all Updates . I would definitely recommend. Great work doing",
    },
    {
      name: "Satish Kumar",
      img: <img src={woman} alt='' />,
      text: "Great place to move our future growth and friendly nature all staff. Good working .",
    },
    {
      name: "Arun Kumar",
      img: <img src={man} alt='' />,
      text: "This job consultancy is completely honest, very cooperative, respond to every queries specifically Anuragi sir ,Thanks to lots ..",
    },
    {
      name: "Sandip Sharma",
      img: <img src={man1} alt='' />,
      text: "Shree Job Consultancy Service is the best in  Raipur, committed to their commitment. Provide best job facility to people's.Best of luck and all the best.",
    },
    {
      name: "Ratan Chakraborty",
      img: <img src={woman} alt='' />,
      text: "Service of Shree job consultant is very good. They provide only genuine jobs. They help me a lot to get a satisfactory job. Behaviour of their staffs very good specially Mr. Kailash. I am very happy with their service.",
    },
  ];

  // State to track the index for the current set of testimonials (4 at a time)
  const [currentIndex, setCurrentIndex] = useState(0);
  const testimonialsPerPage = 4;

  // Function to automatically change to the next set of testimonials
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + testimonialsPerPage) % Testimonial.length);
    }, 3000); // Adjust the time interval for the automatic slide (3000ms = 3 seconds)

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [Testimonial.length]);

  // Get the current set of testimonials (4 at a time)
  let visibleTestimonials = Testimonial.slice(
    currentIndex,
    currentIndex + testimonialsPerPage
  );

  // If the length of visibleTestimonials is less than 4, fill it with the next testimonials from the start
  if (visibleTestimonials.length < testimonialsPerPage) {
    visibleTestimonials = [
      ...visibleTestimonials,
      ...Testimonial.slice(0, testimonialsPerPage - visibleTestimonials.length)
    ];
  }

  // ------------------------------fetch data ------------------------------

  const fetchtestimonial = async function () {
    try {
      axios.get(`${Backend_url}/client/getAll`,
        {
          headers: {
            'Authorization': 'Bearer ' + Token,
          },
        }
      ).then((res) => {
        // const alljson = res.data.json
        setTestimonials(res?.data?.data)
        console.log(res.data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchtestimonial()
  }, []);

  useEffect(() => {
    console.log("visibletestimonials", visibleTestimonials)
  }, [visibleTestimonials]);

  useEffect(() => {
    console.log("Testimonial", Testimonial)
  }, [Testimonial]);

  return (
    <>
      <div className="head">
        <h2>CANDIDATES TESTIMONIAL</h2>
        <div className="testimonial-container">
          <div className="testimonial">
            {visibleTestimonials.map((testimonial, index) => (
              <div key={index} className="testimonial-card">
                <div className="candidate-info">
                  <p className="name">{testimonial.Title}</p>
                </div>
                <div className="testimonial-text">
                  <img src={testimonial.Image} />
                  {/* {testimonial.img} */}
                  <p className="testimonial-text1">"{testimonial.Details}"</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientTestimonial;
