import React, { useEffect, useState } from 'react'
import "./Client.css"
import m0 from "../Images/7.png"
import m1 from "../Images/8.jpg"
import m2 from "../Images/9.png"
import m3 from "../Images/10.gif"
import m4 from "../Images/14.jpg"
import m5 from "../Images/15.jpg"
import m6 from "../Images/16.gif"
import m7 from "../Images/18.jpg"
import m8 from "../Images/26.png"
import m9 from "../Images/27.jpg"
import m10 from "../Images/29.png"
import m11 from "../Images/31.png"
import m12 from "../Images/32.jpg"
import m13 from "../Images/33.png"
import m14 from "../Images/36.jpg"
import m15 from "../Images/37.png"
import m16 from "../Images/38.png"
import m17 from "../Images/41.png"
import m18 from "../Images/43.jpg"
import m19 from "../Images/45.png"
import m20 from "../Images/47.png"
import Backend_url from '../../Important/BackEnd'
import Token from '../../Important/TOKEN'
import axios from 'axios'


function Client() {
  const [slider, setslider] = useState([])
  const [isPaused, setIsPaused] = useState(false);

  const marqueeImage = [
    { id: 0, img: <img src={m0} alt='' /> },
    { id: 1, img: <img src={m1} alt='' /> },
    { id: 1, img: <img src={m2} alt='' /> },
    { id: 1, img: <img src={m3} alt='' /> },
    { id: 1, img: <img src={m4} alt='' /> },
    { id: 1, img: <img src={m5} alt='' /> },
    { id: 1, img: <img src={m6} alt='' /> },
    { id: 1, img: <img src={m7} alt='' /> },
    { id: 1, img: <img src={m8} alt='' /> },
    { id: 1, img: <img src={m9} alt='' /> },
    { id: 1, img: <img src={m10} alt='' /> },
    { id: 1, img: <img src={m11} alt='' /> },
    { id: 1, img: <img src={m12} alt='' /> },
    { id: 1, img: <img src={m13} alt='' /> },
    { id: 1, img: <img src={m14} alt='' /> },
    { id: 1, img: <img src={m15} alt='' /> },
    { id: 1, img: <img src={m16} alt='' /> },
    { id: 1, img: <img src={m17} alt='' /> },
    { id: 1, img: <img src={m18} alt='' /> },
    { id: 1, img: <img src={m19} alt='' /> },
    { id: 1, img: <img src={m20} alt='' /> },
  ]

  const [isScroll, setScroll] = useState(true);

  const handleMouseEnter = () => {
    setScroll(false);
  };

  const handleMouseLeave = () => {
    setScroll(true);
  };

  const handleMouseEnter1 = () => {
    setIsPaused(true);
  };

  // Function to resume animation when mouse leaves
  const handleMouseLeave1 = () => {
    setIsPaused(false);
  };


  const Sliderfetch = async function Sliderfetch() {
    try {
      axios.get(`${Backend_url}/api/sliders/all`, {
        headers: {
          'Authorization': 'Bearer ' + Token,
        },
      }).then((res) => {
        console.log(res.data)
        const alldata = res.data
        const OurClient = alldata.filter((item) => item.SliderName == "OurClient")
        console.log(OurClient)
        setslider(OurClient[0]?.Imagepaths)
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    console.log(slider)
  }, [slider]);

  useEffect(() => {
    Sliderfetch();
  }, []);


  return (
    <>
      <div className='client-container' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className='client-marquee-container'>
          <h1> OUR CLIENTS</h1>
          <div className={`  ${isPaused ? 'paused' : ''} flex overflow-hidden !relative space-x-16 py-[30px]`}>
            <div className="animate-loop">
              {/* First set of images */}
              {slider.map((img, index) => (
                <div className="image" key={index}>
                  <img src={img} />
                </div>
              ))}

              {/* Duplicate the images for seamless effect */}
              {slider.map((img, index) => (
                <div className="image" key={`dup-${index}`}>
                  <img src={img} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Client