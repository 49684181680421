import React, { useEffect, useState } from 'react'
import Footer from '../../Footer/Footer'
import Navbar from '../../Navbar/Navbar'
import "../CSS/payment.css"
import payment from "../../Images/payment.jpg"
import Footer1 from '../../Footer1/Footer1'
import Footerlast from '../../Footerlast/Footerlast'
import qrCode from "../../Images/qrcode.jpg"
import axios from 'axios'
import Backend_url from '../../../Important/BackEnd'
import Token from '../../../Important/TOKEN'

function Payment() {
  const [Scandata, setScandata] = useState([])

  // --------------   fetch marquecontents --------------------------------
  const fetchjson = async () => {
    try {
      axios.get(`${Backend_url}/jsonroute/get`,
        {
          headers: {
            'Authorization': 'Bearer ' + Token,
          },
        }
      ).then((res) => {
        const alljson = res.data.json
        alljson.map((jsondata) => {
          const parsedObject = JSON.parse(jsondata.JsonObject)
          if (parsedObject.name == "qrscan") {
            setScandata(parsedObject?.data)
          }
        })
        console.log(res.data)
      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchjson()
  }, []);

  useEffect(() => {
    console.log(Scandata)
  }, [Scandata]);

  return (
    <>
      <Footer />
      <Navbar />
      <div className='payment-footer sm:!flex-row sm:!p-0 sm:!justify-between'>
        <div className='payment1 sm:!w-auto'>
          <h1>PAYMENT</h1>
        </div>
        <div className='payment2 sm:!w-auto'>
          <ul className={`sm:!m-0 sm:!right-0 sm:!w-auto`}>
            <li>Home</li>
            <li>/</li>
            <li>payment</li>
          </ul>
        </div>
      </div>
      <div className="payment-container sm:flex-col">
        <div className="payment-img h-[610px]  sm:h-auto object-cover sm:!w-full">
          <img src={payment} className={`h-full  bg-cover bg-center`} alt="" />
        </div>
        <div className="patment-method sm:!w-full">
          {/* <h1>Payment Methods</h1>
                <p>Registration</p>
                <p>Service</p> */}

          <div className="qr-payment-container">
            <h2>{Scandata.mainhead}</h2>
            <p>{Scandata.submainhead}</p>
            <div className="qr-code-image ">
              <img src={qrCode} alt="QR Code for Payment" />
            </div>
            <div className="or">
              <p className='p1'>OR</p>
              <p className='text-black p2'> And you make to pay below bank detalis</p>
            </div>

            <div className="qr-payment-details">
              <div className="order-summary">
                <p><strong>Account Name -</strong> {Scandata["Account Name"]}
                  <p><strong>Account Number -</strong>{Scandata["Account Number"]}</p>
                  <p><strong>IFSC Code</strong>-{Scandata["IFSC Code"]}</p> </p> </div>
            </div>
          </div>
        </div>
      </div>
      <Footer1 />
      <Footerlast />
    </>


  )
}

export default Payment
