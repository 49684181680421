import React, { useEffect, useState } from 'react'
import "./footerlast.css"

function Footerlast() {
  const initialCount = 6552;



  const [visitorCount, setVisitorCount] = useState(0);


  return (
    <div>
      <div className="footerlast">
        <div className="techcherry">
          <p className={`sm:!m-0 sm:py-[5px] sm:px-[10px]`}> Shree Job Consultant. All Rights Reserved © 2017 Developed By <span className='text-gray-500'>Techcherry </span> </p>
        </div>
        <div className="counter sm:!hidden">
          <p className='text-black'>Total visitor</p>
          <a href="https://www.hitwebcounter.com" target="_blank" rel="noopener noreferrer">
            <img
              src="https://hitwebcounter.com/counter/counter.php?page=16888506&style=0001&nbdigits=5&type=page&initCount=6552"
              title="Counter Widget"
              alt="Visit counter For Websites"
              border="0"
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footerlast
